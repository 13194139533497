<template>
  <div class="md:flex">
    <MoleculesTitleCard
      heading="新着ニュース"
      color="cream"
      class="md:w-1/2 shrink-0 md:mb-0 mb-5"
    >
      <NuxtLink
        v-for="item in latestNewses"
        :key="item.permalink!"
        :to="createArticleLink(item.permalink!)"
        class="flex p-2 hover:opacity-70 duration-300"
      >
        <div class="shrink-0 w-1/4 rounded-lg mr-4 overflow-hidden">
          <img
            v-if="item.image_file"
            :src="
              createArticleImageUrl({ path: item.image_file, w: 150, h: 150 })
            "
          />
          <AtomsPlaceholderImage v-else class="w-full" />
        </div>
        <p>{{ item.title }}</p>
      </NuxtLink>

      <div class="border-t border-gray-300 p-4 text-right">
        <NuxtLink to="/public/article/news" class="underline font-bold"
          >もっと見る</NuxtLink
        >
      </div>
    </MoleculesTitleCard>

    <MoleculesTitleCard
      heading="週間ランキング"
      color="cream"
      class="md:w-1/2 shrink-0"
    >
      <NuxtLink
        v-for="(item, i) in popularNewses"
        :key="item.permalink!"
        :to="createArticleLink(item.permalink!)"
        class="flex p-2 hover:opacity-70 duration-300"
      >
        <div class="shrink-0 w-1/4 mr-4 relative">
          <div class="rounded-lg overflow-hidden">
            <img
              v-if="item.image_file"
              :src="
                createArticleImageUrl({ path: item.image_file, w: 150, h: 150 })
              "
            />
            <AtomsPlaceholderImage v-else class="w-full" />
          </div>
          <div
            class="absolute -top-1 -left-1 bg-red-400 text-white text-sm rounded-lg px-2 leading-1 py-0.5 shadow"
          >
            {{ i + 1 }}位
          </div>
        </div>
        <p>{{ item.title }}</p>
      </NuxtLink>

      <div class="border-t border-gray-300 p-4 text-right">
        <NuxtLink
          :to="{
            path: '/public/article/news',
            query: { order: 'rank' },
          }"
          class="underline font-bold"
          >もっと見る</NuxtLink
        >
      </div>
    </MoleculesTitleCard>
  </div>
</template>

<script setup lang="ts">
import type { GetArticlesQuery } from '@shared/gql/graphql';
import { useImageUrl } from '@shared/composables/useImageUrl';

defineProps({
  latestNewses: {
    type: Array as PropType<GetArticlesQuery['article']>,
    required: true,
  },
  popularNewses: {
    type: Array as PropType<GetArticlesQuery['article']>,
    required: true,
  },
});

const { createArticleImageUrl } = useImageUrl();
</script>
